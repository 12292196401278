<template>
  <fragment>
    <tr
      class="dividerRow"
      v-if="
        (storageLocation &&
          storageLocation.locationName &&
          prevStorageLocation &&
          storageLocation.locationName != prevStorageLocation.locationName) ||
        !prevStorageLocation
      "
    >
      {{
        storageLocation.locationName
          ? storageLocation.locationName
          : 'Standalone'
      }}
    </tr>
    <tr class="tableRow customRow" @click="openStorage(storageLocation)">
      <td>{{ storageLocation.name }}</td>
      <td @click.stop="isOpen = !isOpen" class="menuTd">
        <CustomClickMenu
          v-if="isOpen"
          :items="[
            {
              title: 'Edit',
              icon: 'create-outline',
              action: editStorageLocation,
              actionData: storageLocation.id,
            },
          ]"
        />
        <ion-icon name="ellipsis-vertical-outline"></ion-icon>
      </td>
    </tr>
  </fragment>
</template>

<script>
import CustomClickMenu from '@/components/CustomClickMenu'

export default {
  data() {
    return {
      isOpen: false
    }
  },
  components: {
    CustomClickMenu,
  },
  props: ['openStorage', 'storageLocation', 'prevStorageLocation', 'openMenu', 'editStorageLocation']
}
</script>

<style lang="scss">
.customRow {
  cursor: pointer;
  position: relative;
}
.customRow:hover {
  background: #f5f5f5;
}
.menuTd {
  text-align: right;
  position: relative;
  width: 50px;
}
</style>