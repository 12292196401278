<template>
  <div class="batchTableWrapper">
    <table class="standardTable">
      <tr class="standardTableHeader">
        <th>Batch</th>
        <th v-if="!isMobile">Price</th>
        <th>Amount</th>
        <th v-if="!isMobile">Creation time</th>
        <th v-if="!isMobile">Expiration date</th>
        <th>Information</th>
        <th v-if="!isMobile">Creator</th>
      </tr>
      <BatchRowItem v-for="(batch, i) in batches" :key="i" :batch="batch" />
    </table>
  </div>
</template>

<script>
import BatchRowItem from '@/components/storage/inventory/BatchRowItem'
import { mapGetters } from 'vuex'


export default {
  props: ["batches", "index"],
  components: {
    BatchRowItem
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      isMobile: 'isMobile',
    }),
  }
}
</script>

<style scoped>
.batchTableWrapper {
  width: 100%;
}
</style>