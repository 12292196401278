<template>
  <div :style="computeStyle" @click="changeInventory(index)" class="tabItem">
    <p>{{storageLocation.name}}</p>
  </div>
</template>

<script>
import { accent } from './../../../assets/css/variables/theme.scss'

export default {
  props: ["storageLocation", "index", "changeInventory", "activeInventory"],
  computed: {
    computeStyle() {
      if(this.index != this.activeInventory) {
        return {background: 'white'}
      } else {
        return {background: accent, color: 'white'}
      }
    }
  }
}
</script>

<style scoped>
  .tabItem {
    cursor: pointer;
    padding: 10px 15px;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 10px;
    white-space: nowrap;
    box-shadow: 0px 0px 5px -2px rgb(189 189 189);

  }
</style>