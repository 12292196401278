<template>
  <div class="popupBox">
    <div class="popContent">
      <div class="popTopRow">
        <ion-icon
          style="cursor: pointer"
          @click="closePopup"
          name="close-outline"
        ></ion-icon>
      </div>
      <div class="popMiddleRow">
        <p>Register sold units </p>
        <div class="popMiddleInput">
          <p>Write how much is sold (max {{batch.amount}} {{batch.unit}})</p>
          <input
            type="number"
            :max="batch.amount"
            min="0"
            placeholder="Amount"
            v-model="amount"
          />
        </div>
      </div>

      <div class="popBottomRow">
        <MainButton
          :clickFunction="closePopup"
          title="Cancel"
          styleClass="secondary"
          :buttonStyle="buttonStyle"
        />
        <MainButton
          :clickFunction="saveChanges"
          title="Register"
          styleClass="primary"
          :buttonStyle="buttonStyle"
        />
      </div>
      <div v-if="errorMessage" class="errorMessage">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import MainButton from '@/layout/MainButton.vue'

export default {
  props: ['batch'],
  components: { MainButton },
  data() {
    return {
      amount: null,
      groupName: '',
      baseGroup: undefined,
      errorMessage: null,
      buttonStyle: {
        padding: '10px 25px 8px 25px',
        fontSize: '10pt',
      },
    }
  },
  methods: {
    saveChanges() {
      axios.post('storage/registerSoldUnits', {
        soldAmount: this.amount,
        storageItemId: this.batch.storage_information_id,
        userId: this.$store.state.selectedUser.id,
        batchId: this.batch.id
      })
        .then(res => {
          this.closePopup()
        })
        .catch(err => {
          if (err.response.data) {
            if (err.response.data.errors) {
              this.errorMessage = err.response.data.errors[0].msg
            } else {
              this.errorMessage = err.response.data.msg
            }
          }
        })
    },
    closePopup() {
      this.$store.commit('CLEAR_POPUP')
    },
  },
}
</script>

<style scoped src="@/assets/css/popup.css"></style>
<style scoped lang="scss">
.popContent {
  width: 100%;
  position: relative;
  display: inline;
}

/* Pop top row */

.popTopRow {
  width: 100%;
  text-align: right;
  font-size: 24pt;
}

/* Pop middle row */

.popMiddleRow {
  position: relative;
  margin: 10px;
  margin-top: 0px;
}
.popMiddleRow p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
  font-size: 13pt;
}

.popMiddleInput {
  position: relative;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.popMiddleInput input {
  border: none;
  border-bottom: 1px solid rgb(99, 99, 99);
  padding: 3px;
  position: relative;
  width: 100%;
  margin-top: 5px;
  transition: 0.2s ease-in-out;
}

.popMiddleInput input:focus {
  border-bottom: 1px solid $accent;
}

.popMiddleInput p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: normal;
  font-size: 9pt;
}

.popMiddleInput ion-icon {
  position: absolute;
  right: 0px;
  bottom: 50%;
  transform: translateY(100%);
}

/* Pop bottom row */

.popBottomRow {
  position: relative;
  margin: 10px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
</style>