<template>
  <div class="pageWrapper">
    <H2>Inventory</H2>
    <!-- <MainButton title="Refresh" :clickFunction="refreshData" /> -->
    <br />
    <div class="locationWrapper">
      <!-- <input
        class="search"
        type="text"
        placeholder="Search"
        v-model="searchString"
      /> -->
      <div class="tags">
        <Tags
          v-for="(tag, index) in tags"
          :key="index"
          :tag="tag"
          :activeTags="activeTags"
          :index="index"
          :addTag="addTag"
        />
      </div>
      <div class="inventoryWrapper">
        <div class="sortingWrapper">
          <div class="filters">
            <SearchInput placeholder="Search" v-model="searchString" />
          </div>
          <!-- SHOP SELECT -->
          <select
            class="dropDownSelect"
            style="margin: 13px 0"
            v-dragscroll
            v-if="shops"
            v-model="selectedShop"
            @change="$store.commit('CLEAR_INVENTORY_PATH')"
          >
            <option :value="undefined" :selectedShop="selectedShop">
              Select shop
            </option>
            <option value="all" :selectedShop="selectedShop">All shops</option>
            <option value="0" :selectedShop="selectedShop">Standalone</option>
            <option
              class="tab"
              :value="shop.id"
              v-for="shop in shops"
              :key="shop.id"
            >
              {{ shop.locationName }}
            </option>
          </select>
          <md-switch
            style="margin-left: 10px"
            title="Group all products in selected storage"
            v-model="groupPerStorageStatus"
          />
        </div>
        <!-- INVENTORY PATH -->
        <div class="inventoryPath">
          <span @click="clearInventoryPath">
            <span v-if="getInventoryPath.length > 0">
              {{ currentShopName }}
            </span>
          </span>
          <span
            v-for="storageLocationId in getInventoryPath"
            :key="storageLocationId"
          >
            <ion-icon
              name="chevron-forward-outline"
              style="top: 2px; position: relative"
            ></ion-icon>
            <span @click="goBackStorageLocation(storageLocationId)">
              <InventoryPathString :storageLocationId="storageLocationId" />
            </span>
          </span>
        </div>
        <div v-if="selectedShop">
          <!-- ========= STORAGE LOCATION DIVIDED ========== -->
          <!-- STORAGES TABLE -->
          <div v-if="!$store.state.inventory.groupPerStorageStatus">
            <div v-if="storageLocations">
              <div v-if="storageLocations.length > 0">
                <table class="standardTable" cellspacing="0" cellpadding="0">
                  <tr class="standardTableHeader">
                    <th>Storage</th>
                    <th></th>
                  </tr>

                  <StorageRowItem
                    v-for="(storageLocation, index) in filteredStorageLocations"
                    :key="index"
                    :editStorageLocation="editStorageLocation"
                    :openStorage="openStorage"
                    :storageLocation="storageLocation"
                    :prevStorageLocation="filteredStorageLocations[index - 1]"
                    :openMenu="openMenu"
                  />
                </table>
              </div>
              <div v-else>
                <p>No storages found</p>
              </div>
            </div>
            <!-- PRODUCTS TABLE -->
            <div v-if="currentStorageProducts">
              <StorageProductsTable
                v-if="getInventoryPath.length > 0"
                :products="currentStorageProducts"
              />
            </div>
            <div v-else>
              <LoadingRowTable />
            </div>
          </div>
          <!-- ========= SUMMARIZED PRODUCTS IN STORAGE ========== -->
          <div v-else>
            <div v-if="summarizedData">
              <ProductInShopTable :summarziedProductsData="summarizedData" />
            </div>
            <div v-else>
              <LoadingRowTable />
            </div>
          </div>
        </div>
        <!-- SHOP SELECTOR -->
        <div v-else>
          <table class="standardTable" cellspacing="0" cellpadding="0">
            <fragment v-for="(shop, index) in shops" :key="index">
              <tr
                class="tableRow"
                @click="
                  () => {
                    selectedShop = shop.id
                  }
                "
              >
                <td>{{ shop.locationName }}</td>
              </tr>
            </fragment>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import { dragscroll } from 'vue-dragscroll'

import InventoryTabs from '@/components/storage/inventory/InventoryTabs'
import Tags from '@/components/storage/inventory/Tags'
import StorageRowItem from '@/components/storage/inventory/StorageRowItem'
import StorageProductsTable from '@/components/storage/inventory/storageLocationInShop/StorageProductsTable'
import ProductInShopTable from '@/components/storage/inventory/summarizedProducts/ProductInShopTable'
import LoadingRowTable from '@/components/LoadingRowTable'
import MainButton from '@/layout/MainButton'
import InventoryPathString from '@/components/storage/inventory/InventoryPathString'
import SearchInput from '@/layout/SearchInput'
import H2 from '@/layout/typography/H2'


export default {
  directives: {
    dragscroll
  },
  components: {
    InventoryTabs,
    Tags,
    StorageRowItem,
    StorageProductsTable,
    ProductInShopTable,
    LoadingRowTable,
    MainButton,
    InventoryPathString,
    SearchInput,
    H2
  },
  data() {
    return {
      selectedShop: undefined,
      shops: undefined,
      storageLocations: undefined,
      summarizedData: undefined,
      currentStorageProducts: undefined,
      searchString: '',
      activeInventory: 0,
      tags: [],
      activeTags: [],
      isOpen: false,
      searchStringInventory: '',
      currentShopName: ''
    }
  },
  created() {
    this.getShops()
  },
  mounted() {
    if (this.$route.params.shopId) {
      this.selectedShop = this.$route.params.shopId
    }
    if (!this.selectedShop) {
      this.clearInventoryPath()
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      isMobile: 'isMobile',
    }),
    filteredStorageLocations() {
      return this.storageLocations.sort((a, b) => {
        if (a.locationName < b.locationName || a.locationName == null) {
          return -1;
        }
        if (a.locationName > b.locationName) {
          return 1;
        }
        return 0;
      })
    },
    groupPerStorageStatus: {
      get() {
        return this.$store.state.inventory.groupPerStorageStatus
      },
      set() {
        this.$store.commit('TOGGLE_GROUP_PER_STORAGE_STATUS')
      }
    },
    getInventoryPath() {
      if (this.$route.query['ipath[]']) {
        if (typeof this.$route.query['ipath[]'] == 'string') {
          return [this.$route.query['ipath[]']]
        }
        return this.$route.query['ipath[]']
      } else {
        return []
      }
    }
  },
  watch: {
    '$route.params.shopId': {
      immediate: true,
      handler() {
        this.selectedShop = this.$route.params.shopId
        this.refreshData()
      },
    },
    activeTags: {
      immediate: false,
      handler() {
        this.refreshData()
      },
    },
    selectedShop: {
      immediate: false,
      handler(val) {
        if (!(this.$route.params.shopId == val)) {
          this.$router.replace({ path: '', params: { shopId: val } })
          this.refreshData()
        }
      },
    },
    getInventoryPath() {
      this.refreshData()
    }
  },
  sockets: {
    pushInventory() {
      this.refreshData()
    },
  },
  methods: {
    clearInventoryPath() {
      this.$router.push({
        query: {
          'ipath[]': undefined
        }
      })
    },
    getShopNameFromStorageId() {
      if (this.getInventoryPath.length > 0) {
        axios.post('storage/getShopNameFromStorageId', { storageLocationId: this.getInventoryPath[0] })
          .then(res => {
            this.currentShopName = res.data
          })
          .catch(err => {
            console.log(err);
          })
      } else {
        this.currentShopName = 'Standalone'
      }
    },
    openMenu() {
      this.isOpen = !this.isOpen
    },
    editStorageLocation(storageLocationId) {
      this.$router.push({
        name: "ViewStorageLocation",
        params: { storageLocationId },
      });
    },
    resetData() {
      this.storageLocations = undefined
      this.summarizedData = undefined
      this.currentStorageProducts = undefined
    },
    refreshData() {
      this.resetData()
      this.getSumOfProductsInLocation()
      this.getStorageLocationsInShop()
      this.getProductsFromStorage()
      this.getShopNameFromStorageId()
      this.joinInventorySocketRoom()
    },
    joinInventorySocketRoom() {
      if (this.getInventoryPath.length > 0) {
        this.$socket.emit('joinInventorySocketRoom', this.getStorageLocationId())
      }
    },
    getProductsFromStorage() {
      if (this.getInventoryPath.length > 0) {
        axios.post('storage/getProductsFromStorage', { storageLocationId: this.getStorageLocationId() })
          .then(res => {
            this.currentStorageProducts = res.data
          })
          .catch(err => {
            console.log(err);
          })
      } else {
        this.currentStorageProducts = []
      }
    },
    goBackStorageLocation(id) {
      let tempArr = JSON.parse(JSON.stringify(this.getInventoryPath))
      let index = tempArr.findIndex(el => el == id)
      tempArr.splice(index + 1)
      this.$router.push({
        query: {
          'ipath[]': tempArr
        }
      })
    },
    openStorage(storageLocation) {
      let tempArr = JSON.parse(JSON.stringify(this.getInventoryPath))
      tempArr.push(storageLocation.id)
      this.$router.push({
        query: {
          'ipath[]': tempArr
        }
      })
      this.refreshData()
    },
    addTag(index) {
      if (this.activeTags.includes(index)) {
        this.activeTags.splice(this.activeTags.indexOf(index), 1)
      } else {
        this.activeTags.push(index)
      }
    },
    formatTags() {
      if (this.activeTags.length > 0) {
        let stringtag = ""
        this.activeTags.forEach(element => {
          stringtag = stringtag + "," + element
        });
        stringtag = stringtag.substring(1)
        return stringtag
      } else {
        return ''
      }
    },
    getStorageLocationsInShop() {
      axios.post('storage/getStorageLocationsInShop', { shopId: this.selectedShop, tags: this.formatTags(), storageLocationId: this.getStorageLocationId() }).then((res) => {
        if (res.data) {
          this.storageLocations = res.data
        }
      })
    },
    getStorageLocationId() {
      let storageLocationId = undefined
      if (this.getInventoryPath && this.getInventoryPath.length > 0) {
        storageLocationId = this.getInventoryPath[this.getInventoryPath.length - 1]
      }
      return storageLocationId
    },
    getShops() {
      axios.post('storage/getOnlyShops').then((res) => {
        if (res.data && res.data.length > 0) {
          this.shops = res.data
        }
      })
    },
    getSumOfProductsInLocation() {
      axios.post('storage/getSumOfProductsInLocation', { shopId: this.selectedShop, storageLocationId: this.getStorageLocationId() }).then((res) => {
        if (res.data) {
          this.summarizedData = res.data
        }
      })
    },
  },
}
</script>

<style scoped>
.tableRow {
  cursor: pointer;
}

.tableRow:hover {
  background: #f3f3f3;
}

.tags {
  display: flex;
}

.locationWrapper {
  position: relative;
  width: 100%;
  background: white;
  display: inline;
}

.noInventory {
  padding: 15px;
}

.noInventory p {
  font-family: 'Spartan', sans-serif;
  margin: 5px;
}

.inventoryPath span {
  cursor: pointer;
}

.tab {
  cursor: pointer;
  padding: 13px 20px;
  white-space: nowrap;
}

.search {
  margin: 15px 0;
  padding: 10px;
  border: 1px solid rgb(202, 202, 202);
  border-radius: 5px;
}

.inventoryWrapper {
  position: relative;
  width: 100%;
  height: auto;
}

.sortingWrapper {
  display: flex;
}

.filters {
  padding: 10px 0;
  margin-right: 10px;
}

@media screen and (max-width: 700px) {
  .sortingWrapper {
    display: block;
  }
}
</style>