var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[(
      (_vm.storageLocation &&
        _vm.storageLocation.locationName &&
        _vm.prevStorageLocation &&
        _vm.storageLocation.locationName != _vm.prevStorageLocation.locationName) ||
      !_vm.prevStorageLocation
    )?_c('tr',{staticClass:"dividerRow"},[_vm._v(" "+_vm._s(_vm.storageLocation.locationName ? _vm.storageLocation.locationName : 'Standalone')+" ")]):_vm._e(),_c('tr',{staticClass:"tableRow customRow",on:{"click":function($event){return _vm.openStorage(_vm.storageLocation)}}},[_c('td',[_vm._v(_vm._s(_vm.storageLocation.name))]),_c('td',{staticClass:"menuTd",on:{"click":function($event){$event.stopPropagation();_vm.isOpen = !_vm.isOpen}}},[(_vm.isOpen)?_c('CustomClickMenu',{attrs:{"items":[
          {
            title: 'Edit',
            icon: 'create-outline',
            action: _vm.editStorageLocation,
            actionData: _vm.storageLocation.id,
          } ]}}):_vm._e(),_c('ion-icon',{attrs:{"name":"ellipsis-vertical-outline"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }