<template>
  <fragment>
    <tr style="cursor: pointer" @click="openBatchDropdown()" class="tableRow">
      <td>
        {{ product.productName }}
      </td>
      <td v-if="!isMobile">{{ product.inventories.length }}</td>
      <td>{{ product.amount }} {{ product.unit }}</td>
      <fragment v-if="!isMobile">
        <td
          v-if="
            product.customerOrder.productId && product.customerOrder.orderTotal
          "
        >
          {{ product.customerOrder.orderTotal }} {{ product.unit }}
        </td>
        <td v-else>N/A</td>
      </fragment>
      <fragment v-if="!isMobile">
        <td
          :class="
            product.customerOrder.difference < 0
              ? 'differenceMinus'
              : 'differencePlus'
          "
          v-if="product.customerOrder.orderTotal"
        >
          {{ Math.abs(product.customerOrder.difference) }}
          {{ product.unit }}
        </td>
        <td v-else>N/A</td>
      </fragment>
    </tr>
    <tr>
      <td :style="{padding: 0, 'padding-left':(isMobile ? 15 : 30) + 'px'}" :colspan="isMobile ? 2 : 5">
        <div
          class="batchScrollWrapper"
          :id="'openProductItem' + product.id"
          :style="openOrderStyle"
        >
          <InventoriesInProductTable
            :inventories="product.inventories"
            :product="product"
          />
        </div>
      </td>
    </tr>
  </fragment>
</template>

<script>
import InventoriesInProductTable from './InventoriesInProductTable'

export default {
  components: {
    InventoriesInProductTable
  },
  data() {
    return {
      isOpen: false,
    }
  },
  props: ["product"],
  computed: {
    openOrderStyle() {
      if (this.product) {
        let el = document.querySelector(
          '#openProductItem' + this.product.id + ' .dropdown'
        )
        if (el) {
          var height = window.getComputedStyle(el).height
        }

        if (this.isOpen) {
          return { height: height }
        } else {
          return { height: '0px' }
        }
      }
    },
    isMobile() {
      return this.$store.getters.isMobile
    }
  },
  methods: {
    openBatchDropdown() {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style scoped>
.lineWrapper {
  display: flex;
  width: 100px;
}
.dateLine {
  height: 5px;
}
.batchScrollWrapper {
  transform-origin: top;
  transition: 0.5s ease-in-out;
  display: block;
  overflow: hidden;
  position: relative;
  height: auto;
  border-radius: 5px;
}

tr th,
tr td {
  padding: 13px 20px;
}

td img {
  width: 20px;
  margin-right: 20px;
  border-radius: 50%;
}

tr th:first-child,
tr td:first-child {
  text-align: left;
}

tr th {
  background: #e4e4e7;
  color: #575151;
  text-align: left;
}

.tableRow:hover {
  background: #f5f5f5;
}

.differenceMinus {
  color: red;
}

.differencePlus {
  color: rgb(46, 161, 0);
}

.differenceMinus::before {
  content: '- ';
}

.differencePlus::before {
  content: '+ ';
}
tr:first-child {
  background: none;
}
</style>