<template>
  <fragment>
    <tr style="cursor: pointer" @click="openBatchDropdown()" class="tableRow">
      <td>
        {{ inventory.name }}
      </td>
      <td>
        {{ inventory.amount }}
        {{ product.unit }}
      </td>
    </tr>
    <tr>
      <td
        :style="{ padding: 0, 'padding-left': (isMobile ? 15 : 30) + 'px' }"
        colspan="5"
      >
        <div
          class="batchScrollWrapper"
          :id="'openInventoryItem' + inventory.id"
          :style="openOrderStyle"
        >
          <BatchTable v-if="batches" :batches="batches" />
        </div>
      </td>
    </tr>
  </fragment>
</template>

<script>
import axios from 'axios'
import BatchTable from '../BatchTable.vue'
import InventoriesInProductTable from './InventoriesInProductTable'

export default {
  components: {
    InventoriesInProductTable,
    BatchTable
  },
  data() {
    return {
      isOpen: false,
      batches: []
    }
  },
  props: ["inventory", "product"],
  computed: {
    openOrderStyle() {
      if (this.product) {
        let el = document.querySelector(
          '#openInventoryItem' + this.inventory.id + ' .dropdown'
        )
        if (el) {
          var height = window.getComputedStyle(el).height
        }

        if (this.isOpen) {
          return { height: height }
        } else {
          return { height: '0px' }
        }
      }
    },
    isMobile() {
      return this.$store.getters.isMobile
    }
  },
  methods: {
    openBatchDropdown() {
      if (!this.batches.length > 0) {
        this.getBatches()
      }
      this.isOpen = !this.isOpen
    },
    getBatches() {
      axios.post('storage/getBatches', {
        shopId: this.product.shop_id,
        storageLocationId: this.inventory.storage_location_id,
        productId: this.product.id,
        unit: this.product.unit,
      }).then((res) => {
        if (res.data) {
          this.batches = res.data
        }
      })
    },
  }
}
</script>

<style scoped>
.lineWrapper {
  display: flex;
  width: 100px;
}
.dateLine {
  height: 5px;
}
.batchScrollWrapper {
  transform-origin: top;
  transition: 0.5s ease-in-out;
  display: block;
  overflow: hidden;
  position: relative;
  height: auto;
  border-radius: 5px;
}
.tableRow:hover {
  background: #f5f5f5;
}

tr th,
tr td {
  padding: 13px 20px;
}

td img {
  width: 20px;
  margin-right: 20px;
  border-radius: 50%;
}

tr th:first-child,
tr td:first-child {
  text-align: left;
}

tr th {
  background: #e4e4e7;
  color: #575151;
  text-align: left;
}

.differenceMinus {
  color: red;
}

.differencePlus {
  color: rgb(46, 161, 0);
}

.differenceMinus::before {
  content: '- ';
}

.differencePlus::before {
  content: '+ ';
}
tr:first-child {
  background: none;
}
</style>