<template>
  <div @click="addTag(tag.id)" :style="computedStyle" class="tag">
    <p>{{tag.name}}</p>
  </div>
</template>

<script>
export default {
  props: ["tag", "activeTags", "index", "addTag"],
  computed: {
    computedStyle() {
      if(this.activeTags.includes(this.tag.id)) {
        return {background: 'rgb(243, 167, 26)', color: 'white'}
      }
    }
  }
}
</script>

<style scoped>
.tag {
  transition: .1s ease-in-out;
  padding:3px 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: white;
  width: auto;
  margin: 0 5px 10px 0;
  border-radius: 5px;
  cursor: pointer;
}
.tag p {
  font-family: 'spartan', sans-serif;
  font-size: 8pt;
}
</style>
