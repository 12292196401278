<template>
  <div>
    <div class="wrapper">
      <table class="standardTable" cellspacing="0" cellpadding="0">
        <tr class="standardTableHeader">
          <th>Product</th>
          <th v-if="!$store.getters.isMobile">Inventories</th>
          <th>In stock</th>
          <th v-if="!$store.getters.isMobile">Customers orders</th>
          <th v-if="!$store.getters.isMobile">Difference</th>
        </tr>
        <LoadingRowTable :dataList="summarziedProductsData" :columnsAmount="5">
          <fragment v-for="product in summarziedProductsData" :key="product.id">
            <ProductInShopRow :product="product" />
          </fragment>
        </LoadingRowTable>
      </table>
    </div>
  </div>
</template>

<script>
import ProductInShopRow from '@/components/storage/inventory/summarizedProducts/ProductInShopRow'
import LoadingRowTable from '@/components/LoadingRowTable.vue'

export default {
  props: ['summarziedProductsData', 'searchString'],
  components: {
    ProductInShopRow,
    LoadingRowTable
  },
}
</script>

<style scoped>
.wrapper {
  width: 100%;
}

tr th,
tr td {
  padding: 10px 20px;
}

td img {
  width: 20px;
  margin-right: 20px;
  border-radius: 50%;
}

tr th:first-child,
tr td:first-child {
  text-align: left;
}

.differenceMinus {
  color: red;
}

.differencePlus {
  color: rgb(46, 161, 0);
}

.differenceMinus::before {
  content: '- ';
}

.differencePlus::before {
  content: '+ ';
}
tr:first-child {
  background: none;
}
</style>