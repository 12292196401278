<template>
  <div class="wrapper" v-if="inventories">
    <table
      class="standardTable"
      v-if="inventories.length > 0"
      cellspacing="0"
      cellpadding="0"
    >
      <tr class="standardTableHeader">
        <th width="150">Inventory</th>
        <th width="150">Amount</th>
      </tr>
      <fragment v-for="(inventory) in filteredInventories" :key="inventory.id">
        <InventoryInProductRow :inventory="inventory" :product="product" />
      </fragment>
    </table>
    <div v-else>
      <p>No products found</p>
    </div>
  </div>
</template>

<script>
import InventoryInProductRow from './InventoryInProductRow'


export default {
  props: ['inventories', 'searchString', 'product'],
  components: {
    InventoryInProductRow
  },
  computed: {
    filteredInventories() {
      return this.inventories.filter((inventory) => {
        if (!this.searchString) {
          return inventory
        }
        if (inventory.name.toLowerCase().includes(this.searchString.toLowerCase())) {
          return inventory
        }
      })
    },
  },
}
</script>

<style scoped>
.wrapper {

  width: 100%;
}

.standardTable {
  table-layout: auto;
}

tr th,
tr td {
  padding: 10px 20px;
}

td img {
  width: 20px;
  margin-right: 20px;
  border-radius: 50%;
}

tr th:first-child,
tr td:first-child {
  text-align: left;
}


.differenceMinus {
  color: red;
}

.differencePlus {
  color: rgb(46, 161, 0);
}

.differenceMinus::before {
  content: '- ';
}

.differencePlus::before {
  content: '+ ';
}
tr:first-child {
  background: none;
}
</style>