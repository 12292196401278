<template>
  <div class="wrapper">
    <table class="standardTable" cellspacing="0" cellpadding="0">
      <tr class="standardTableHeader">
        <th>Product</th>
        <th>In stock</th>
        <th v-if="!isMobile">Customers orders</th>
        <th v-if="!isMobile">Difference</th>
      </tr>
      <LoadingRowTable :dataList="products" :columnsAmount="4">
        <fragment v-for="(product, index) in filteredProducts" :key="index">
          <StorageLocationInShopRow
            :activeTab="activeTab"
            :product="product"
            :index="index"
          />
        </fragment>
      </LoadingRowTable>
    </table>
  </div>
</template>

<script>
import StorageLocationInShopRow from '@/components/storage/inventory/storageLocationInShop/StorageLocationInShopRow'
import LoadingRowTable from '@/components/LoadingRowTable.vue'
import { mapGetters } from 'vuex'


export default {
  props: ['activeTab', 'products', 'searchString', 'activeInventory'],
  components: {
    StorageLocationInShopRow,
    LoadingRowTable
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      isMobile: 'isMobile',
    }),
    filteredProducts() {
      if (this.products) {

        return this.products.filter((product) => {
          if (!this.searchString) {
            return product
          }
          if (product.productName.toLowerCase().includes(this.searchString.toLowerCase())) {
            return product
          }
        })
      }
    },
  },
}
</script>

<style scoped>
.wrapper {
  width: 100%;
}

tr th,
tr td {
  padding: 10px 20px;
}

td img {
  width: 20px;
  margin-right: 20px;
  border-radius: 50%;
}

tr th:first-child,
tr td:first-child {
  text-align: left;
}

.differenceMinus {
  color: red;
}

.differencePlus {
  color: rgb(46, 161, 0);
}

.differenceMinus::before {
  content: '- ';
}

.differencePlus::before {
  content: '+ ';
}
tr:first-child {
  background: none;
}
</style>