<template>
  <fragment>
    <ContextMenu
      ref="batchRowMenu"
      :contextMenuList="customContextMenuDataList"
    />
    <tr
      @click="
        () => {
          openBatch(batch.batchNumber)
        }
      "
      class="tableRow"
      v-context-menu="'batchRowMenu'"
    >
      <td :style="getDateDiff">#{{ batch.batchNumber }}</td>
      <td v-if="!$store.getters.isMobile">{{ batch.batch_price }}</td>
      <td>{{ batch.amount }} {{ batch.unit }}</td>
      <td v-if="!$store.getters.isMobile">
        {{ formatDateWithTime(batch.creation_time) }}
      </td>
      <td v-if="!$store.getters.isMobile">
        {{ formatDate(batch.expiration_date) }}
        <fragment
          v-if="
            batch.expiration_date &&
            calculateDaysLeft(batch.expiration_date) != 0
          "
        >
          ({{ Math.abs(calculateDaysLeft(batch.expiration_date)) }}
          {{
            calculateDaysLeft(batch.expiration_date) > 0
              ? 'days left'
              : ' days old'
          }})
        </fragment>
      </td>
      <td>{{ batch.other_info }}</td>
      <td v-if="!$store.getters.isMobile">{{ batch.username }}</td>
    </tr>
  </fragment>
</template>

<script>
import { formatDate, formatDateWithTime } from '../../../globalFunctions/date'
import RegisterSoldPopup from '../RegisterSoldPopup.vue'
import ReportLossPopup from '../ReportLossPopup.vue'

export default {
  props: ["batch"],
  data() {
    return {
      customContextMenuDataList: [
        {
          title: 'Mark as sold',
          action: () => { this.$store.commit('SET_POPUP', { component: RegisterSoldPopup, properties: { batch: this.batch } }) },
          icon: 'logo-usd'
        },
        {
          title: 'Report loss',
          action: () => { this.$store.commit('SET_POPUP', { component: ReportLossPopup, properties: { batch: this.batch } }) },
          icon: 'trash'
        }
      ]
    }
  },
  methods: {
    formatDateWithTime,
    formatDate,
    openBatch(number) {
      this.$router.push({ name: 'Batch', params: { batchId: number } })
    },
    calculateDaysLeft(date) {
      let now = new Date()
      let difference = ((new Date(date) - now) / (1000 * 3600 * 24)).toFixed()
      return difference
    }
  },
  computed: {
    getDateDiff() {
      if (this.batch.expiration_date) {
        let creation_time = new Date(this.batch.creation_time)
        let expiration_date = new Date(this.batch.expiration_date)
        var difference = ((expiration_date - creation_time) / (24 * 3600 * 1000));

        if (difference < 1) {
          return { 'border-left': '7px solid #b50000' }
        } else if (difference < 3) {
          return { 'border-left': '7px solid #ffc400' }
        } else {
          return { 'border-left': '7px solid rgb(46, 177, 74)' }

        }

      } else {
        return { 'border-left': '7px solid lightgray' }

      }
    }
  }
}
</script>

<style scoped>
.tableRow:hover {
  background: #f5f5f5;
  cursor: pointer;
}
</style>