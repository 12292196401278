<template>
  <fragment>
    <tr style="cursor: pointer" @click="openBatchDropdown()" class="tableRow">
      <td>
        {{ product.productName }}
      </td>
      <td>{{ product.amount }} {{ product.unit }}</td>
      <fragment v-if="!isMobile">
        <td
          v-if="
            product.customerOrder.productId && product.customerOrder.orderTotal
          "
        >
          {{ product.customerOrder.orderTotal }} {{ product.unit }}
        </td>
        <td v-else>N/A</td>
      </fragment>
      <fragment v-if="!isMobile">
        <td
          :class="
            product.customerOrder.difference < 0
              ? 'differenceMinus'
              : 'differencePlus'
          "
          v-if="product.customerOrder.orderTotal"
        >
          {{ Math.abs(product.customerOrder.difference) }}
          {{ product.unit }}
        </td>
        <td v-else>N/A</td>
      </fragment>
    </tr>
    <tr>
      <td :style="{padding: 0, 'padding-left':(isMobile ? 15 : 30) + 'px'}" :colspan="isMobile ? 2 : 4">
        <div
          class="batchScrollWrapper"
          :id="'openOrderItem' + index"
          :style="openOrderStyle"
        >
          <BatchTable v-if="batches" :index="index" :batches="batches" />
        </div>
      </td>
    </tr>
  </fragment>
</template>

<script>
import BatchTable from '@/components/storage/inventory/BatchTable'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  components: {
    BatchTable
  },
  data() {
    return {
      isOpen: false,
      batches: []
    }
  },
  props: ["activeTab", "product", "index"],
  computed: {
    openOrderStyle() {
      let el = document.querySelector('#openOrderItem' + this.index + ' .dropdown')
      if (el) {
        var height = window.getComputedStyle(el).height
      }

      if (this.isOpen && this.batches.length > 0) {
        return { height: height }
      } else {
        return { height: '0px' }
      }
    },
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      isMobile: 'isMobile',
    }),
  },
  methods: {
    openBatchDropdown() {
      if (!this.batches.length > 0) {
        this.getBatches()
      }
      this.isOpen = !this.isOpen
    },
    getBatches() {
      axios.post('storage/getBatches', {
        shopId: 'all',
        storageLocationId: this.product.storage_location_id,
        productId: this.product.id,
        unit: this.product.unit,
      }).then((res) => {
        if (res.data) {
          this.batches = res.data
        }
      })
    },
  }
}
</script>

<style scoped>
.lineWrapper {
  display: flex;
  width: 100px;
}
.dateLine {
  height: 5px;
}
.batchScrollWrapper {
  transform-origin: top;
  transition: 0.5s ease-in-out;
  display: block;
  overflow: hidden;
  position: relative;
  height: auto;
  border-radius: 5px;
}

.tableRow:hover {
  background: #f5f5f5;
}
tr th,
tr td {
  padding: 13px 20px;
}

td img {
  width: 20px;
  margin-right: 20px;
  border-radius: 50%;
}

tr th:first-child,
tr td:first-child {
  text-align: left;
}

tr th {
  background: #e4e4e7;
  color: #575151;
  text-align: left;
}

.differenceMinus {
  color: red;
}

.differencePlus {
  color: rgb(46, 161, 0);
}

.differenceMinus::before {
  content: '- ';
}

.differencePlus::before {
  content: '+ ';
}
tr:first-child {
  background: none;
}
</style>