<template>
  <div class="menu">
    <div
      @click="item.action(item.actionData)"
      v-for="item in items"
      :key="item.title"
      class="menuItem"
    >
      <ion-icon :name="item.icon"></ion-icon>
      <p>{{ item.title }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['items']
}
</script>

<style scoped lang="scss">
.menu {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 100%;
  z-index: 20;
  border-radius: 7px;
  box-shadow: 0px 0px 10px -2px rgb(189 189 189);
}
.menuItem {
  background: white;
  display: flex;
  align-items: center;
  padding: 5px;
  transition: .2s ease-in-out;
}
.menuItem:hover {
  background: rgb(236, 236, 236);
}
.menuItem hr {
  width: 90%;
}
.menuItem ion-icon {
  font-size: 15pt;
  margin: 0 5px;
  position: relative;
  bottom: 1px;
  color: rgb(102, 102, 102);
}
.menuItem p {
  font-family: 'Avenir';
  font-weight: lighter;
  font-size: 10pt;
  color: rgb(102, 102, 102);
  margin-right: 10px;
}
.menuItem:first-child {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.menuItem:last-child {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
</style>