<template>
  <fragment>{{storageLocationName}}</fragment>
</template>

<script>
import axios from 'axios'

export default {
  props: ['storageLocationId'],
  created() {
    axios.post('storage/getStorageLocationName', { storageLocationId: this.storageLocationId })
      .then(res => {
        this.storageLocationName = res.data
      })
      .catch(err => {
        console.log(err);
      })
  },
  data() {
    return {
      storageLocationName: ''
    }
  }
}
</script>

<style>
</style>